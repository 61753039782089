
import { defineComponent, inject, ref } from "vue";
import fixedHeader from "@/components/common/Header.vue";
import fixedFooter from "@/components/common/Footer.vue";
export default defineComponent({
  name: "Maintain",
  components: {
    fixedHeader,
    fixedFooter
  }
});
